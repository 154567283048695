import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HeaderBannerEstimatePg } from "../components/Estimate-Page/header-banner-estimate"
import { EstimateBody } from "../components/Estimate-Page/estimate-body"


const EstimatePage = (props) => {
    return (
        <Layout>
            <Seo
                title="Kaylar Construction: Get A Free Estimate for Kitchen Remodeling, Bathroom Remodeling, Siding, Windows & Doors, Decks & Patios, and Interior Design Services"
                description="Get a free estimate for your kitchen remodeling, bathroom remodeling, siding, windows & doors, decks & patios, and interior design projects. Contact us now to unlock your home's potential with our expert general contracting services."
                location={props.location.pathname}
                keywords={[
                    'Kaylar Construction',
                    'Free Estimate'
                ]}
            />
            <div>
                <HeaderBannerEstimatePg />
                <EstimateBody />
            </div>
        </Layout>
    )
}
export default EstimatePage